import React from 'react';
import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react';
import podcastsData from '../data/podcasts.json';

const Footer = () => {
  const { socialMedia, podcastInfo } = podcastsData;

  const SocialIcon = ({ platform, url }) => {
    const iconProps = { size: 24, className: "hover:text-gray-300" };
    const title = `Follow us on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`;
    
    const IconComponent = () => {
      switch (platform) {
        case 'facebook':
          return <Facebook {...iconProps} />;
        case 'instagram':
          return <Instagram {...iconProps} />;
        case 'twitter':
          return <Twitter {...iconProps} />;
        case 'youtube':
          return <Youtube {...iconProps} />;
        case 'spotify':
          return (
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="hover:text-gray-300">
              <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
            </svg>
          );
        case 'appleMusic':
          return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="hover:text-gray-300">
              <path d="M23.97 6.58c0-.61-.05-1.21-.15-1.8-.08-.59-.24-1.15-.49-1.68-.25-.53-.59-1.01-1-1.43-.41-.42-.89-.76-1.42-1.01-.53-.25-1.09-.41-1.68-.49-.59-.1-1.19-.15-1.8-.15H6.57c-.61 0-1.21.05-1.8.15-.59.08-1.15.24-1.68.49-.53.25-1.01.59-1.43 1-.42.41-.76.89-1.01 1.42-.25.53-.41 1.09-.49 1.68-.1.59-.15 1.19-.15 1.8v10.84c0 .61.05 1.21.15 1.8.08.59.24 1.15.49 1.68.25.53.59 1.01 1 1.43.41.42.89.76 1.42 1.01.53.25 1.09.41 1.68.49.59.1 1.19.15 1.8.15h10.84c.61 0 1.21-.05 1.8-.15.59-.08 1.15-.24 1.68-.49.53-.25 1.01-.59 1.43-1 .42-.41.76-.89 1.01-1.42.25-.53.41-1.09.49-1.68.1-.59.15-1.19.15-1.8V6.58zm-3.35 11.62c0 .32-.05.63-.14.93-.1.3-.24.57-.42.82-.18.25-.4.47-.65.65-.25.18-.52.32-.82.42-.3.09-.61.14-.93.14H6.34c-.32 0-.63-.05-.93-.14-.3-.1-.57-.24-.82-.42-.25-.18-.47-.4-.65-.65-.18-.25-.32-.52-.42-.82-.09-.3-.14-.61-.14-.93V5.8c0-.32.05-.63.14-.93.1-.3.24-.57.42-.82.18-.25.4-.47.65-.65.25-.18.52-.32.82-.42.3-.09.61-.14.93-.14h11.32c.32 0 .63.05.93.14.3.1.57.24.82.42.25.18.47.4.65.65.18.25.32.52.42.82.09.3.14.61.14.93v12.4z"/>
              <path d="M16.13 9.32l-5.22-1.53c-.12-.04-.25-.05-.38-.03-.13.02-.25.06-.36.13-.11.07-.2.16-.27.27-.07.11-.11.23-.13.36v6.81c-.33-.21-.72-.33-1.13-.33-.58 0-1.14.23-1.55.64-.41.41-.64.97-.64 1.55s.23 1.14.64 1.55c.41.41.97.64 1.55.64s1.14-.23 1.55-.64c.41-.41.64-.97.64-1.55v-5.46l4.35 1.28V16c-.33-.21-.72-.33-1.13-.33-.58 0-1.14.23-1.55.64-.41.41-.64.97-.64 1.55s.23 1.14.64 1.55c.41.41.97.64 1.55.64s1.14-.23 1.55-.64c.41-.41.64-.97.64-1.55V9.68c0-.12-.03-.24-.09-.35-.06-.11-.14-.2-.24-.27-.1-.07-.22-.12-.34-.14-.12-.02-.25-.01-.37.03z"/>
            </svg>
          );
        default:
          return null;
      }
    };

    return (
      <a href={url} aria-label={title} title={title}>
        <IconComponent />
      </a>
    );
  };

  return (
    <footer className="bg-[rgb(160,70,71)] dark:bg-[rgb(160,70,71)] text-white py-8">
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div>
            <h3 className="text-2xl font-bold mb-2">Contact Us</h3>
            <p>{podcastInfo.email}</p>
          </div>
          <div className="flex space-x-4">
            {Object.entries(socialMedia).map(([platform, url]) => (
              <SocialIcon key={platform} platform={platform} url={url} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;