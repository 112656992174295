import React from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import podcastsData from '../data/podcasts.json';
import newsData from '../data/news.json';
import reviewsData from '../data/reviews.json';
import commentaryData from '../data/commentary.json';

const HomePage = () => {
  
  
  // Function to get the latest episode across all categories
  const getLatestEpisode = () => {
    const allEpisodes = [
      ...(newsData.episodes || []),
      ...(reviewsData.episodes || []),
      ...(commentaryData.episodes || [])
    ];

    return allEpisodes.sort((a, b) => new Date(b.date) - new Date(a.date))[0] || null;
  };

  const latestEpisode = getLatestEpisode();
  const { podcastInfo, socialMedia } = podcastsData;

  const SpotifyIcon = ({ size = 24, className = "" }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} className={className}>
      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
    </svg>
  );

  const AppleMusicIcon = ({ size = 36, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} className={className}>
      <path d="M23.97 6.58c0-.61-.05-1.21-.15-1.8-.08-.59-.24-1.15-.49-1.68-.25-.53-.59-1.01-1-1.43-.41-.42-.89-.76-1.42-1.01-.53-.25-1.09-.41-1.68-.49-.59-.1-1.19-.15-1.8-.15H6.57c-.61 0-1.21.05-1.8.15-.59.08-1.15.24-1.68.49-.53.25-1.01.59-1.43 1-.42.41-.76.89-1.01 1.42-.25.53-.41 1.09-.49 1.68-.1.59-.15 1.19-.15 1.8v10.84c0 .61.05 1.21.15 1.8.08.59.24 1.15.49 1.68.25.53.59 1.01 1 1.43.41.42.89.76 1.42 1.01.53.25 1.09.41 1.68.49.59.1 1.19.15 1.8.15h10.84c.61 0 1.21-.05 1.8-.15.59-.08 1.15-.24 1.68-.49.53-.25 1.01-.59 1.43-1 .42-.41.76-.89 1.01-1.42.25-.53.41-1.09.49-1.68.1-.59.15-1.19.15-1.8V6.58zm-3.35 11.62c0 .32-.05.63-.14.93-.1.3-.24.57-.42.82-.18.25-.4.47-.65.65-.25.18-.52.32-.82.42-.3.09-.61.14-.93.14H6.34c-.32 0-.63-.05-.93-.14-.3-.1-.57-.24-.82-.42-.25-.18-.47-.4-.65-.65-.18-.25-.32-.52-.42-.82-.09-.3-.14-.61-.14-.93V5.8c0-.32.05-.63.14-.93.1-.3.24-.57.42-.82.18-.25.4-.47.65-.65.25-.18.52-.32.82-.42.3-.09.61-.14.93-.14h11.32c.32 0 .63.05.93.14.3.1.57.24.82.42.25.18.47.4.65.65.18.25.32.52.42.82.09.3.14.61.14.93v12.4z"/>
      <path d="M16.13 9.32l-5.22-1.53c-.12-.04-.25-.05-.38-.03-.13.02-.25.06-.36.13-.11.07-.2.16-.27.27-.07.11-.11.23-.13.36v6.81c-.33-.21-.72-.33-1.13-.33-.58 0-1.14.23-1.55.64-.41.41-.64.97-.64 1.55s.23 1.14.64 1.55c.41.41.97.64 1.55.64s1.14-.23 1.55-.64c.41-.41.64-.97.64-1.55v-5.46l4.35 1.28V16c-.33-.21-.72-.33-1.13-.33-.58 0-1.14.23-1.55.64-.41.41-.64.97-.64 1.55s.23 1.14.64 1.55c.41.41.97.64 1.55.64s1.14-.23 1.55-.64c.41-.41.64-.97.64-1.55V9.68c0-.12-.03-.24-.09-.35-.06-.11-.14-.2-.24-.27-.1-.07-.22-.12-.34-.14-.12-.02-.25-.01-.37.03z"/>
    </svg>
  );

  const PodcastIcon = ({ platform, url }) => {
    const iconProps = { size: 36, className: "fill-current text-red-700 hover:text-red-600" };
    const title = `Listen on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`;
    
    let Icon;
    switch (platform) {
      case 'spotify':
        Icon = SpotifyIcon;
        break;
      case 'appleMusic':
        Icon = AppleMusicIcon;
        break;
      default:
        return null;
    }

    return (
      <a href={url} aria-label={title} title={title} className="mr-2">
        <Icon {...iconProps} />
      </a>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>{podcastInfo.name} - Home</title>
        <meta name="description" content={podcastInfo.description} />

        {/* OpenGraph meta tags */}
        <meta property="og:title" content={latestEpisode ? `${latestEpisode.title} - ${podcastInfo.name}` : podcastInfo.name} />
        <meta property="og:description" content={latestEpisode ? latestEpisode.description : podcastInfo.description} />
        <meta property="og:image" content="https://news-from-the-pew.netlify.app/{latestEpisode.thumbnail}" />
        <meta property="og:url" content="https://www.newsfromthepew.com" /> {/* Replace with your actual URL */}
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={latestEpisode ? `${latestEpisode.title} - ${podcastInfo.name}` : podcastInfo.name} />
        <meta name="twitter:description" content={latestEpisode ? latestEpisode.description : podcastInfo.description} />
        <meta name="twitter:image" content={latestEpisode ? latestEpisode.thumbnail : '/path/to/default-image.jpg'} />
      </Helmet>
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-[1080px] mx-auto">
          <Navigation />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-[1080px]">
        <section className="mb-12" id="intro-section">
          {/*<h2 className="text-4xl font-bold mb-4 text-gray-800 dark:text-gray-200">{podcastInfo.name}</h2>*/}
          <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
            <img src="/assets/images/Tai-Aimee.webp" alt={`${podcastInfo.hosts.join(' and ')}, hosts of ${podcastInfo.name}`} className="w-full aspect-[24/10] object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2 text-red-700 dark:text-red-500">{podcastInfo.name}</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">{podcastInfo.description}</p>
              <div className="flex items-center">
                <span className="mr-2 text-gray-700 dark:text-gray-300">Listen on:</span>
                {Object.entries(socialMedia)
                  .filter(([platform]) => ['spotify', 'appleMusic'].includes(platform))
                  .map(([platform, url]) => (
                    <PodcastIcon key={platform} platform={platform} url={url} />
                  ))
                }
              </div>
            </div>
          </div>
        </section>

        {latestEpisode && (
          <section className="mb-12" id="latest-episode-section">
            <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Latest Episode</h3>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2">
                  <img src={latestEpisode.thumbnail} alt={latestEpisode.title} className="w-full h-full object-cover" />
                </div>
                <div className="w-full md:w-1/2 p-6">
                  <h4 className="text-xl font-semibold mb-2 text-red-700 dark:text-red-500">{latestEpisode.title}</h4>
                  <p className="text-sm font-bold text-gray-600 dark:text-gray-400 mb-2">Air Date: {latestEpisode.date}</p>
                  <p className="text-gray-600 dark:text-gray-400 mb-4">{latestEpisode.description}</p>
                </div>
              </div>
              <div className="p-6">
                <audio controls className="w-full">
                  <source src={latestEpisode.audioFile} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </section>
        )}

        <section className="mb-12" id="reviews-section">
          <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Reviews</h3>
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <p className="text-gray-600 dark:text-gray-400 italic">"News from the Pew offers insightful commentary on the evolving landscape of American Christianity. A must-listen!"</p>
            <p className="text-gray-800 dark:text-gray-200 font-semibold mt-2">- Christian Today Magazine</p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;